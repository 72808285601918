import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addEditCreateGrnAsset',
  components: {},
  props: ['legal_entity', 'erp_org_id', 'current_location', 'erp_loc_org_id'],
  watch: {
    currentPage: function() {
      this.getPoNumber();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPoNumber();
    }
  },
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      ponumber: null,
      poNoData: [],
      poNoFields: [
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'po_date',
          label: 'PO Date'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name'
        }
      ]
    };
  },
  mounted() {
    this.getPoNumber();
  },
  methods: {
    getPoNumber() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        po_number: this.ponumber,
        le_id: this.erp_org_id,
        loc_id: this.erp_loc_org_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getPoNumList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.poNoData = resp.data.data.data;
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.$emit('closeModal', item.po_number);
    },
    clearData() {
      this.ponumber = null;
    }
  },
  beforeDestroy() {}
};
