import commonHelper from '@/app/utility/common.helper.utility';
export default {
  props: ['rowData','selectedItems','detailsFlag'],
  name: 'AssetPutAwayDetails',
  components: {},
  watch: {
    batchCurrentPage() {
      this.getAllocationGridData();
    },
    batchPerPage() {
      this.currentPage = 1;
      this.getAllocationGridData();
    }
  },
  data() {
    return {
      loader: false,
      rack: null,
      shelf: null,
      bin: null,
      allocationRackData: [
      ],
      allocationRackFields: [
        {
            key: 'shelf_details'
          },
        {
          key: 'locator_name',
          label:'Rack Name'
        },
        {
          key:'freeze_status'
        },
      ],
      allocationShelfData: [],
      allocationShelfFields: [
        {
          key: 'bin_details'
        },
        {
            key: 'locator_name',
            label:'Shelf Name'
          },
        {
          key:'freeze_status'
        },
      ],
      allocationBinData: [],
      allocationBinFields: [
        {
            key: 'locator_name',
            label:'Bin Name'
          },
        {
          key:'freeze_status'
        },
      ],
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      locatorData:[],
      rackName:null,
      shelfName: null
    };
  },
  validations: {},
  mounted() {
    this.getAllocationGridData()
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'upload') {
    //       this.showExcelUploadModal = true;
    //     }
    //   }
    // });
    
    // const active = item.active ? 'Yes' : !item.active ? 'No' : null;
    // const result = item.concatenated_segment + active;
    // this.$emit('closeModal', result);
  },
  methods: {
    rowSelected(item) {
    this.locatorData.map(ele=>{
        if(ele.locator_id==item.parent_locator_id){
            this.shelfName=ele.locator_name
            item.bin_id=item.locator_id
            item.shelf_id=ele.locator_id
        this.locatorData.map(eles=>{
            if(ele.parent_locator_id==eles.locator_id){
                this.rackName=eles.locator_name
                item.rack_id=eles.locator_id
            }
        })
    }
    })
      const res ={
        result: this.rackName+"," +this.shelfName+ ","+ item.locator_name,
        items:item
      }
      this.$emit('closeModal', res);
    },
    getAllocationGridData() {
        const payload = {
            locId: Number(this.selectedItems.selectedFaLocId)
          };
          this.loader = true;
          this.$store
            .dispatch('assets/getPutawayRackShelfBin', payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                this.allocationRackData = resp.data.data.filter(
                  ele => ele.locator_level === 'L1'
                );
                this.allocationShelfData = resp.data.data.filter(
                  ele => ele.locator_level === 'L2'
                );
                this.allocationBinData = resp.data.data.filter(
                  ele => ele.locator_level === 'L3'
                );
                this.locatorData=resp.data.data;
                this.totalRows = resp.data.data.total_elements;
              }
            })
            .catch(() => {
              this.loader = false;
            });
    },
    getShelfDetails(shelfDtl, shelfData) {
        shelfDtl.allocationShelfData = this.allocationShelfData.filter(
          ele => ele.parent_locator_id == shelfDtl.locator_id
        );
        shelfData.toggleDetails();
      },
      getBinDetails(binDtl, binData) {
        binDtl.allocationBinData = this.allocationBinData.filter(
          ele => ele.parent_locator_id == binDtl.locator_id
        );
        binData.toggleDetails();
      },
    clear() {
      this.rack = null;
      this.shelf = null;
      this.bin = null;
    }
  }
};
