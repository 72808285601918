import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'specificGrnModal',
  components: { DatePicker },
  props: ['rowData'],
  watch: {
    currentPage: function() {
      this.getResponsibility();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getResponsibility();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showAddTransferAssetModal: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      currentPage: 1,
      totalRows: null,
      editMode: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      flag: false,
      specificGrnModalData: [],
      specificGrnModalFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name'
        },
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'po_date',
          label: 'PO Date'
        },
        {
          key: 'item_code',
          class: 'itemc'
        },
        {
          key: 'item_desc',
          label: 'Item Description'
        },
        {
          key: 'grn_num',
          label: 'GRN Number'
        },
        {
          key: 'grn_date',
          label: 'GRN Date'
        },
        {
          key: 'fa_cat',
          label: 'FA Category'
        },
        {
          key: 'qty',
          label: 'Quantity'
        },
        {
          key: 'price'
        },
        {
          key: 'date_received'
        },
        {
          key: 'serial_number'
        },
        {
          key: 'hdd_ssd',
          label: 'HDD/SSD'
        },
        {
          key: 'mac_address_wireless'
        },
        {
          key: 'cost_center'
        },
        {
          key: 'mac_address_wired'
        },
        {
          key: 'end_of_warranty'
        },
        {
          key: 'notes'
        },
        {
          key: 'tags'
        },
        {
          key: 'model'
        },
        {
          key: 'color'
        },
        {
          key: 'manufacturer'
        },
        {
          key: 'group_item_category'
        },
        {
          key: 'extended_warranty'
        },
        {
          key: 'type_of_warranty'
        },
        {
          key: 'department'
        },
        {
          key: 'asset_number'
        },
        {
          key: 'hsn_code',
          label: 'HSN code'
        }
      ],
      allFields: false,
      datas: null,
      num: [],
      anum: null,
      quantity: null
    };
  },
  mounted() {
    this.quantity = this.rowData.qty;
    this.getGrnData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.specificGrnModal();

        }
      }
    });
  },
  methods: {
    viewFields() {
      this.allFields = !this.allFields;
    },
    getGrnData() {
      for (let i = 1; i <= this.quantity; i++) {
        if(i==1||i==2||i==3||i==4||i==5||i==6||i==7||i==8||i==9){
          i="0"+i
        }
        this.specificGrnModalData.push({
          fa_cat: this.rowData.fa_cat,
          fa_cat_id: this.rowData.fa_cat_id,
          grn_date: this.rowData.grn_date,
          grn_num: this.rowData.grn_num,
          item_code: this.rowData.item_code,
          item_desc: this.rowData.item_desc,
          item_id: this.rowData.item_id,
          le_id: this.rowData.le_id,
          le_name: this.rowData.le_name,
          ou_id: this.rowData.ou_id,
          ou_name: this.rowData.ou_name,
          po_date: this.rowData.po_date,
          po_hdr_id:this.rowData.po_hdr_id,
          po_number: this.rowData.po_number,
          price: this.rowData.price,
          qty: 1,
          vendor_code: this.rowData.vendor_code,
          vendor_id: this.rowData.vendor_id,
          vendor_name: this.rowData.vendor_name,
          asset_number:this.rowData.grn_num+"-"+i
        });
      }
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
