import commonHelper from '@/app/utility/common.helper.utility';
export default {
  props: ['rowData','selectedItems'],
  name: 'AllocationDetail',
  components: {},
  watch: {
    batchCurrentPage() {
      this.getAllocationGridData();
    },
    batchPerPage() {
      this.currentPage = 1;
      this.getAllocationGridData();
    }
  },
  data() {
    return {
      loader: false,
      rack: null,
      shelf: null,
      bin: null,
      allocationData: [
        // {
        //   rack: 'abc',
        //   shelf: 'def',
        //   bin: 'ghi'
        // },
        // {
        //     rack: '1234',
        //     shelf: 'hgfd',
        //     bin: 'fgbn'
        //   }
      ],
      allocationFields: [
        {
          key: 'rack_name'
        },
        {
          key:'rack_freeze_status'
        },
        {
          key: 'shelf_name'
        },
        {
          key:'shelf_freeze_status'
        },
        {
          key: 'bin_name'
        },
        {
          key:'bin_freeze_status'
        },
      ],
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1
    };
  },
  validations: {},
  mounted() {
    this.getAllocationGridData()
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'upload') {
    //       this.showExcelUploadModal = true;
    //     }
    //   }
    // });
    
    // const active = item.active ? 'Yes' : !item.active ? 'No' : null;
    // const result = item.concatenated_segment + active;
    // this.$emit('closeModal', result);
  },
  methods: {
    rowSelected(item) {
      const res ={
        result: item.rack_name+ item.shelf_name+ item.bin_name,
        items:item
      }
      this.$emit('closeModal', res);
    },
    getAllocationGridData() {
      const payload = {
        params:{
          faLocId:this.selectedItems.selectedFaLocId,
        },
        queryParams:{
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rack_name: this.rack?this.rack:"",
        shelf_name: this.shelf?this.shelf:"",
        bin_name: this.bin?this.bin:""
        }
        
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAllocationGridData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.allocationData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => (this.loader = false));
    },
    clear() {
      this.rack = null;
      this.shelf = null;
      this.bin = null;
    }
  }
};
