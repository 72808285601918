import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import viewModal from '../grnAssetDataModal';
import { required } from 'vuelidate/lib/validators';
import { format } from 'date-fns';
import copyItem from '../grnAssetDataModal/copyItem';
import QRCode from 'qrcode';


export default {
  name: 'createGrnAsset',
  components: { DatePicker, viewModal,copyItem },
  props: ['createRowData', 'locId','getDashboardData','getResponsibility','currentLoc'],
  watch: {
    tags() {
      this.adjustTagsWidth();
    }
  },
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      grnQty: null,
      avlQty:null,
      alreadyCreated: null,
      assetCreateQty: null,
      itemType: {
        value: null,
        text: null
      },
      assetItem: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      loader: false,
      editMode: false,
      unsubscribe: null,
      assetData: null,
      grnassets: [
        {
          label: 'Purchase Details :-',
          class: 'purchase'
        },
        {
          key: 'le_name',
          label: 'Legal Entity',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'ou_name',
          label: 'Operating Unit',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'po_number',
          label: 'PO Number',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'po_date',
          label: 'PO Date',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'order_qty',
          label: ' PO Quantity',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'item_code',
          label: 'Item Code',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'item_desc',
          label: 'Item Description',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'price',
          label: 'Price',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'grn',
          label: 'GRN Details :-',
          class: 'grnDetails'
        },
        {
          key: 'grn_num',
          label: 'GRN Number',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'grn_date',
          label: 'GRN Date',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
      
        {
          key: 'fa_cat',
          label: 'FA Category',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          label: 'Asset Details:-'
        },
        {
          key: 'hsn_code',
          label: 'HSN / SAC code',
          value: null,
          isCalender: 9,
          editsFlag: 2,
          classes: 'mandatory'
        },
        {
          key: 'date_received',
          label: 'Date Received',
          value: null,
          isCalender: 1,
          editsFlag: 2,
          classes: 'mandatory'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          value: null,
          isCalender: 1,
          editsFlag: 2,
          classes: 'mandatory'
        },
        {
          key: 'serial_number',
          label: 'Serial Number',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'hdd_ssd',
          label: 'HDD/SSD',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'mac_address_wireless',
          label: 'Wireless Mac Address',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'mac_address_wired',
          label: 'LAN Mac Address',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'end_of_warranty',
          label: 'End Of Warranty',
          value: null,
          isCalender: 5,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'extended_warranty',
          label: 'Extended Warranty',
          value: null,
          isCalender: 5,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'type_of_warranty',
          label: 'Type Of Warranty',
          value: null,
          meaning:null,
          isCalender: 4,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'notes',
          label: 'Notes',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'tags',
          label: 'Tags',
          value: [],
          isCalender: 2,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'manufacturer',
          label: 'Manfacturer',
          value: null,
          meaning:null,
          isCalender: 6,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'model',
          label: 'Model',
          value: null,
          meaning:null,
          isCalender: 7,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'processor',
          label: 'Processor',
          value: null,
          meaning:null,
          isCalender: 8,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'color',
          label: 'Color',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'group_item_category',
          label: 'Group Item Category',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'department',
          label: 'Department',
          value: null,
          meaning: null,
          isCalender: 3,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'le_id',
          value: null
        },
        {
          key: 'ou_id',
          value: null
        },
        {
          key: 'fa_cat_id',
          value: null
        },
        {
          key: 'item_id',
          value: null
        },
        {
          key: 'line_num',
          value: null
        },
        {
          key: 'po_hdr_id',
          value: null
        },
        {
          key: 'vendor_code',
          value: null
        },
        {
          key: 'vendor_id',
          value: null
        }
      ],
      showViewModal: false,
      rowData: null,
      showValueSetModal: false,
      data: null,
      countMacAddressWireless: null,
      numMacAddressWireless: null,
      countMacAddressWired: null,
      numMacAddressWired: null,
      point:' '+'(Semi-colon(;) seperated)',
      regexPattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\.[0-9a-fA-F]{4}\.[0-9a-fA-F]{4})$/,
      numRegex:/^[0-9]+$/,
      regexFlag: null,
      regexFlag1: null,
      checkFlag: null,
      checkedFlag: null,
      modelValueSetId:null,
      validationError: '',
      showCopyModal:false,
      copyData: null,
      barcode:[],
      assetItemId:null,
      hsnFlag:false,
      countQrcode:0,
    };
  },
  validations: {
    assetCreateQty: {
      required
    },
    itemType: {
      value: { required },
      text: { required }
    },
    assetItem: {
      value: { required },
      text: { required }
    },
  },
  mounted() {
    if (this.createRowData) {
      this.copyData=this.createRowData;
      this.rowData = this.createRowData;
      this.grnQty = this.createRowData.order_qty;
      this.mapRowData(this.createRowData);
      this.formattedCurrency(this.grnassets[10].value)
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'save' &&
          this.editMode &&
          this.$store.state.assets.flag
        ) {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            this.newAsset();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.alreadyCreatedQty();
    // this.adjustTagsWidth();
  },
  computed: {
    limitedItems() {
      return this.grnassets.slice(0, this.grnassets.length - 8);
    },
    disabledDates() {
      this.grnassets[17].value=format(new Date(), 'dd-MMM-yyyy')
      return date => {
        const currentDate = new Date();
        const previousDate = new Date(this.grnassets[6].value);
        previousDate.setHours(0, 0, 0, 0);
        return date > currentDate || date < previousDate;
    }
  },
  },
  methods: {
    showHideCopyModal(flag) {
      this.showCopyModal = flag;
    },
    tempFunc(item) {
      if (item) {
        this.showHideCopyModal(false);
        // this.grnassets[17].value = item.date_received;
        this.grnassets[18].value = item.data.invoice_date;
        this.grnassets[19].value = item.data.serial_number;
        this.grnassets[20].value = item.data.hdd_ssd;
        this.grnassets[21].value = item.data.mac_address_wireless;
        this.grnassets[22].value = item.data.mac_address_wired;
        this.grnassets[23].value = item.data.end_of_warranty;
        this.grnassets[24].value = item.data.extended_warranty;
        this.grnassets[25].value = item.data.type_of_warranty;
        this.grnassets[25].meaning = item.data.type_of_warranty_meaning;
        this.grnassets[26].value = item.data.notes;
        this.getTags(item.asstId); // value of tags which is 27th index
        this.grnassets[28].value = item.data.manufacturer;
        this.grnassets[28].meaning = item.data.manufacturer_name_meaning;
        this.grnassets[29].value = item.data.model;
        this.grnassets[29].meaning = item.data.model_meaning;
        this.grnassets[30].value = item.data.processor_id;
        this.grnassets[30].meaning = item.data.processor;
        this.grnassets[31].value = item.data.color;
        this.grnassets[32].value = item.data.group_item_category;
        this.grnassets[33].value = item.data.department;
        this.grnassets[33].meaning = item.data.department_meaning;
        this.setDefaultModel()
      }
    },
    getTags(id) {
      const payload = {
        asset_id: id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetTags', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.grnassets[27].value = [];
            resp.data.data.map(item => {
              this.grnassets[27].value.push(item);
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setDefaultModel() {
      const modelValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.FA_ASSET_MANUFACTURER,
        this.grnassets[28].value
      );
      modelValue.then(key => {
        this.modelValueSetId = key[0].value_set_dtl_id
      });
    },
    disabledDatesWarranty(date) {
      return commonHelper.disabledDates(date, this.grnassets[18].value);
    },
    newAsset() {
      if (this.itemType.value === 'ASSET' && this.assetItem.text) {
        for (let i = 0; i < this.grnassets.length; i++) {
          if (
            this.grnassets[i].classes === 'mandatory' &&
            (!this.grnassets[i].value || this.grnassets[i].value.length == 0)
          ) {
            // alert(this.grnassets[i].label + ' ' + 'is missing');
            this.$bvToast.toast(this.grnassets[i].label + ' ' + 'is missing', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else if (
            this.grnassets[i].classes === 'mandatory' &&
            (this.grnassets[i].value || this.grnassets[i].value.length !== 0)
          ) {
            this.checkedFlag++;
          }
        }
        if (this.grnassets[19].value && this.checkFlag === this.checkedFlag) {
          if (this.grnassets[21].value && this.grnassets[22].value) {
            this.checkedFlag = null;
            this.validateBothMac();
          } else if (this.grnassets[21].value && !this.grnassets[22].value) {
            this.checkedFlag = null;
            this.validateMacAddressWireless();
          } else if (this.grnassets[22].value && !this.grnassets[21].value) {
            this.checkedFlag = null;
            this.validateMacAddressWired();
          } 
          else {
            this.checkedFlag = null;
            this.validateSerialNo();
          }
        } else {
          this.checkedFlag = null;
        }
      } else if (this.itemType.value === 'CONSUMABLE' && this.assetItem.text) {
        for (let i = 0; i < this.grnassets.length; i++) {
          if (
            this.grnassets[i].classes === 'mandatory' &&
            (!this.grnassets[i].value || this.grnassets[i].value.length == 0)
          ) {
            // alert(this.grnassets[i].label + ' ' + 'is missing');
            this.$bvToast.toast(this.grnassets[i].label + ' ' + 'is missing', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else if (
            this.grnassets[i].classes === 'mandatory' &&
            (this.grnassets[i].value || this.grnassets[i].value.length !== 0)
          ) {
            this.checkedFlag++;
          }
        }

        if (this.checkFlag === this.checkedFlag) {
          if (this.grnassets[21].value && this.grnassets[22].value) {
            this.checkedFlag = null;
            this.validateBothMac();
          } else if (this.grnassets[21].value && !this.grnassets[22].value) {
            this.checkedFlag = null;
            this.validateMacAddressWireless();
          } else if (this.grnassets[22].value && !this.grnassets[21].value) {
            this.checkedFlag = null;
            this.validateMacAddressWired();
          } else if (this.grnassets[19].value) {
            this.checkedFlag = null;
            this.validateSerialNo();
          } else {
            this.checkedFlag = null;
            this.updateGrnAsset();
          }
        } else if (!this.checkFlag) {
          this.checkedFlag = null;
          this.updateGrnAsset();
        } else {
          this.checkedFlag = null;
        }
      }
    },
    mapRowData(createRowData) {
      if (createRowData !== null) {
        for (let i = 0; i < this.grnassets.length; i++) {
          if (createRowData[this.grnassets[i].key]) {
            this.grnassets[i].value = createRowData[this.grnassets[i].key];
          }
        }
        // this.grnassets.slice(0, this.grnassets.length - 1);
      }
    },
    alreadyCreatedQty() {
      const payload = {
        po_number: this.createRowData.po_number,
        // grn_num: this.createRowData.grn_num,
        item_code: this.createRowData.item_code
      };
      this.loader = true;
      this.$store
        .dispatch('assets/alreadyCreatedQty', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.alreadyCreated = resp.data.data;
            this.avlQty=this.grnQty-this.alreadyCreated
            if(this.avlQty==0){
              // alert("Available quantity for this asset is 0")
              this.$bvToast.toast("Available quantity for this asset is 0", {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateCreateQty() {
      if(this.assetCreateQty==0){
        // alert('Asset create quantity can not be zero');
        this.$bvToast.toast('Asset create quantity can not be zero', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.assetCreateQty = null;
      }
      else if (this.assetCreateQty <= this.grnQty - this.alreadyCreated) {
          this.assetCreateQty = this.assetCreateQty;
        } else {
          // alert('Asset create quantity should be less than or equal to Available quantity');
          this.$bvToast.toast('Asset create quantity should be less than or equal to Available quantity', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.assetCreateQty = null;
        }
        
 
    },
    updateGrnAsset() {
       if((this.grnassets[23].value ||this.grnassets[24].value) && !this.grnassets[17].value){
        // alert("Please fill Date Received")
        this.$bvToast.toast("Please fill Date Received", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.dateInput[0].focus();
      });
      this.grnassets[23].value=null;
      this.grnassets[24].value=null;
      }
      else if(this.grnassets[28].value && !this.grnassets[29].value){
        // alert("Please select a Model")
        this.$bvToast.toast("Please select a Model", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.modelInput[0].focus();
      });
      }
      else{
        if(!this.grnassets[16].value){
          this.$bvToast.toast("HSN code cannot be null", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.hsnInput[0].focus();
        });
        }
        else if((this.grnassets[16].value.length<8 && this.grnassets[16].value.length>6) || (this.grnassets[16].value.length<6)){
          this.$bvToast.toast("HSN code length should be of 6 or 8 digits", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.hsnInput[0].focus();
      });
      }else{
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const payload = {
            asset_create_qty: this.assetCreateQty,
            asset_id: 0,
            fa_cat_id: null,
            item_id: null,
            po_hdr_id: null,
            vendor_id: null,
            vendor_code: null,
            line_num: null,
            processor:null,
            color: null,
            cost_center: null,
            date_received: null,
            department: null,
            end_of_warranty: null,
            extended_warranty: null,
            fa_cat: null,
            grn_date: null,
            grn_num: null,
            grn_quantity: this.grnQty,
            group_item_category: null,
            hdd_ssd: null,
            hsn_code: null,
            item_code: null,
            item_desc: null,
            le_id: null,
            mac_address_wired: null,
            mac_address_wireless: [],
            manufacturer: null,
            model: null,
            notes: null,
            ou_id: null,
            po_date: null,
            po_number: null,
            price: null,
            serial_number: null,
            tags_status: null,
            tags: [],
            type_of_warranty: null,
            vendor_name: null,
            fa_item_type: this.itemType.value,
            fa_item_id: this.assetItem.value,
            hr_loc_id:this.locId,
            invoice_date:null,
          };
          for (let k in payload) {
            for (let i = 0; i < this.grnassets.length; i++) {
              if(k === this.grnassets[i].key && k=="price"){
                let numericString = this.grnassets[i].value.replace(/₹|,/g, '');
                let numericValue = parseFloat(numericString);
                let formattedNumber = Math.floor(numericValue);
                payload[k]=formattedNumber
              }
              else if (k === this.grnassets[i].key) {
                payload[k] = this.grnassets[i].value;
              }
            }
          }
          this.loader = true;
          this.$store
            .dispatch('assets/updateGrnAsset', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.$bvToast.toast("Asset created successfully", {
                  title: 'Alert',
                  variant: 'success',
                  solid: true
                });
                this.getDashboardData();
                if(this.itemType.text=='ASSET'){
                this.generateMultipleBarcode(response.data.data);
                }
                this.showHideViewModal(true);
                this.getResponsibility();
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
        
      }
    },
    async generateMultipleBarcode(value) {
      // Assuming `this.barcode` contains an array of barcode values
      this.barcode = value;
      // Create an array to hold SVG elements for each barcode
      // const barcodeSVGs = this.barcode.map(barcodeValue => {
      //   // Create a container div for each barcode with "Delhivery" text at the top
      //   const containerDiv = document.createElement('div');
      //   containerDiv.style.textAlign='center',
      //   containerDiv.classList.add('barcode-container');
    
      //   // Add "Delhivery" text
      //   const delhiveryText = document.createElement('h1');
      //   delhiveryText.textContent = 'DELHIVERY';
      //   delhiveryText.style.marginBottom='30px',
      //   delhiveryText.style.fontWeight ='bold';
      //   delhiveryText.style.fontSize ='xxx-large';
      //   containerDiv.appendChild(delhiveryText);
    
      //   // Generate barcode SVG
      //   const svgElement = document.createElement('svg');
      //   JsBarcode(svgElement, barcodeValue, {
      //     format: 'CODE128',
      //     displayValue: true,
      //     height: 100,
      //     width: 2.8,
      //     fontSize:30,
      //   });
    
      //   // Append barcode SVG to the container
      //   containerDiv.appendChild(svgElement);
    
      //   return containerDiv;
      // });
      const qrCodeSVGs = await Promise.all(this.barcode.map(async qrCodeValue => {
        // Create a container div for each QR code with "Delhivery" text at the top
        const containerDiv = document.createElement('div');
        containerDiv.style.textAlign = 'center';
        containerDiv.classList.add('qr-code-container');
        containerDiv.style.marginTop = '20px';

        // Add "Delhivery" text
        const delhiveryText = document.createElement('h1');
        delhiveryText.textContent = 'DELHIVERY';
        delhiveryText.style.fontWeight = 'bold';
        delhiveryText.style.fontSize = '80px';
        delhiveryText.style.margin = 'auto';
        delhiveryText.style.paddingTop='50px';
        if(this.countQrcode>0){
          delhiveryText.style.paddingTop='60px';
        }
        this.countQrcode++
        containerDiv.appendChild(delhiveryText);

        // Generate QR code SVG
        const svgElement = document.createElement('div');
        await QRCode.toString(qrCodeValue, { type: 'img', width: 300 }, (err, img) => {
          if (err) throw err;
          svgElement.innerHTML = img;
        });

        // Append QR code SVG to the container
        containerDiv.appendChild(svgElement);

        // Add "QRcode" text
        const qrCodeText = document.createElement('p');
        qrCodeText.textContent = qrCodeValue;
        qrCodeText.style.fontSize = '90px';
        qrCodeText.style.fontWeight = 'bold';
        qrCodeText.style.margin = 'auto';
        containerDiv.appendChild(qrCodeText);

        return containerDiv;
      }));
      // Create an HTML document to contain all barcodes
      const qrCodeDocument = document.implementation.createHTMLDocument('QRCodes');
      const qrCodeContainer = qrCodeDocument.body;
    
      // Append each barcode container to the document
      qrCodeSVGs.forEach(containerDiv => {
        qrCodeContainer.appendChild(containerDiv);
      });
    
      // Open the qrcodes in a new tab
      const qrCodeWindow = window.open('', '_blank');
      qrCodeWindow.document.write(qrCodeDocument.documentElement.outerHTML);
    
      // Wait for the document to load in the new tab, then trigger print
        qrCodeWindow.print();
        qrCodeWindow.close(); // Close the tab after printing
    },
    showHideViewModal(flag) {
      this.showViewModal = flag;
      this.$store.commit('assets/createModalFlag', false);
      if (!flag) {
        this.$emit('closeModal');
      }
    },
    validateSerialNo() {
      const serialNumberLength = this.grnassets[19].value.split(';');
      const checkValidtn= serialNumberLength.filter(ele => 
        ele.length<5 || ele.length>20
      )
      if(checkValidtn.length!==0){
        this.$bvToast.toast("Serial Number length should be of 5-20 characters only", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[14].focus();
      });
      }else{
      if (this.assetCreateQty == serialNumberLength.length) {
        this.updateGrnAsset();
      } else {
        if (this.assetCreateQty > serialNumberLength.length) {
          this.$bvToast.toast('Asset Create Quantity is greater than serial number length.', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.assetCreateInput.focus();
        });
        } else {
          // alert('Asset Quantity is less than serial number length.');
          this.$bvToast.toast('Asset Create Quantity is less than serial number length.', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.assetCreateInput.focus();
        });
        }
      }
    }
    },
    validateMacAddressWireless() {
      const check=appStrings.specialChar.some(i=>this.grnassets[21].value.includes(i))
      if(check){
        // alert("Special characters are not allowed except ':' or '-'")
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[16].focus();
      });
      }
      else{
      const serialMacAddressWirelessLength = this.grnassets[21].value.split(
        ';'
      );
      const checkValidtn= serialMacAddressWirelessLength.filter(ele => 
        ele.length!==17
      )
      if(checkValidtn.length!==0){
        // alert("Wireless Mac Address includes ';' inbetween the format or length is not equal to 17")
        this.$bvToast.toast("Wireless Mac Address includes ';' inbetween the format or length is not equal to 17", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[16].focus();
      });
      }else{
        for (let i = 0; i < serialMacAddressWirelessLength.length; i++) {
          if (
            this.regexPattern.test(serialMacAddressWirelessLength[i]) &&
            serialMacAddressWirelessLength[i].length === 17
          ) {
            this.regexFlag++;
          } else {
            // alert(
            //   "Wireless Mac Address should be of 17 including '-' / ':' and a-f/A-F"
            // );
            this.$bvToast.toast("Wireless Mac Address should be of 17 including '-' / ':' and a-f/A-F", {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[16].focus();
          });
            this.regexFlag = 0;
          }
      }
      }

      if (serialMacAddressWirelessLength.length === this.regexFlag) {
        this.regexFlag = 0;
        this.assetCreateQty = +this.assetCreateQty;
        if (this.assetCreateQty === serialMacAddressWirelessLength.length) {
          // this.updateGrnAsset();
          this.validateSerialNo();
        } else {
          if (this.assetCreateQty > serialMacAddressWirelessLength.length) {
            // alert('Wireless Mac Address number is less than Asset Quantity');
            this.$bvToast.toast('Wireless Mac Address number is less than Asset Quantity', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[16].focus();
          });
          } else if (
            this.assetCreateQty < serialMacAddressWirelessLength.length &&
            serialMacAddressWirelessLength.length > 0
          ) {
            // alert('Wireless Mac Address number is more than Asset Quantity');
            this.$bvToast.toast('Wireless Mac Address number is more than Asset Quantity', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[16].focus();
          });
          }
        }
         }
      }
    },
    validateMacAddressWired() {
      const check=appStrings.specialChar.some(i=>this.grnassets[22].value.includes(i))
      if(check){
        // alert("Special characters are not allowed except ':' or '-'")
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[17].focus();
      });
      }
      else{
      const serialMacAddressWiredLength = this.grnassets[22].value.split(';');
      const checkValidtn= serialMacAddressWiredLength.filter(ele => 
        ele.length!==17
      )
      if(checkValidtn.length!==0){
        // alert("LAN Mac Address includes ';' inbetween the format or length is not equal to 17")
        this.$bvToast.toast("LAN Mac Address includes ';' inbetween the format or length is not equal to 17", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[17].focus();
      });
      }else{
      for (let i = 0; i < serialMacAddressWiredLength.length; i++) {
        if (
          this.regexPattern.test(serialMacAddressWiredLength[i]) &&
          serialMacAddressWiredLength[i].length === 17
        ) {
          this.regexFlag1++;
        } else {
          // alert(
          //   "LAN Mac Address format should be of length 17 including '-' / ':' and a-f/A-F"
          // );
          this.$bvToast.toast("LAN Mac Address format should be of length 17 including '-' / ':' and a-f/A-F", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.myInputs[17].focus();
        });
          this.regexFlag1 = 0;
        }
    }
    }

      if (serialMacAddressWiredLength.length === this.regexFlag1) {
        this.regexFlag1 = 0;
        this.assetCreateQty = +this.assetCreateQty;
        if (this.assetCreateQty === serialMacAddressWiredLength.length) {
          this.validateSerialNo();
        } else {
          if (this.assetCreateQty > serialMacAddressWiredLength.length) {
            // alert('LAN Mac Address number is less than Asset Quantity');
            this.$bvToast.toast('LAN Mac Address number is less than Asset Quantity', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[17].focus();
          });
          } else if (
            this.assetCreateQty < serialMacAddressWiredLength.length &&
            serialMacAddressWiredLength.length > 0
          ) {
            // alert('LAN Mac Address number is more than Asset Quantity');
            this.$bvToast.toast('LAN Mac Address number is more than Asset Quantity', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[17].focus();
          });
          }
        }
        }
      }
    },
    validateBothMac() {
      const check=appStrings.specialChar.some(i=>this.grnassets[21].value.includes(i))
      if(check){
        // alert("Special characters are not allowed except ':' or '-'")
        this.$bvToast.toast("Special characters are not allowed except ':' or '-'", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.myInputs[16].focus();
      });
      }
      else{
        const serialMacAddressWirelessLength = this.grnassets[21].value.split(
          ';'
        );
        const checkValidtn= serialMacAddressWirelessLength.filter(ele => 
          ele.length!==17
        )
        if(checkValidtn.length!==0){
          // alert("Wireless Mac Address includes ';' inbetween the format or length is not equal to 17")
          this.$bvToast.toast("Wireless Mac Address includes ';' inbetween the format or length is not equal to 17", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.myInputs[16].focus();
        });
        }else{
      for (let i = 0; i < serialMacAddressWirelessLength.length; i++) {
        if (
          this.regexPattern.test(serialMacAddressWirelessLength[i]) &&
          serialMacAddressWirelessLength[i].length === 17
        ) {
          this.regexFlag++;
        } else {
          // alert(
          //   "Wireless Mac Address format should be of length 17 including '-' / ':' and a-f/A-F "
          // );
          this.$bvToast.toast("Wireless Mac Address format should be of length 17 including '-' / ':' and a-f/A-F ", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.$nextTick(() => {
            this.$refs.myInputs[16].focus();
        });
          this.regexFlag = 0;
        }
      }
    }
      if (serialMacAddressWirelessLength.length === this.regexFlag) {
        this.regexFlag = 0;
        this.assetCreateQty = +this.assetCreateQty;
        if (this.assetCreateQty === serialMacAddressWirelessLength.length) {
          this.validateMacAddressWired();
        } else {
          if (this.assetCreateQty > serialMacAddressWirelessLength.length) {
            // alert('Wireless Mac Address number is less than Asset Quantity');
            this.$bvToast.toast('Wireless Mac Address number is less than Asset Quantity', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[16].focus();
          });
          } else if (
            this.assetCreateQty < serialMacAddressWirelessLength.length &&
            serialMacAddressWirelessLength.length > 0
          ) {
            // alert('Wireless Mac Address number is more than Asset Quantity');
            this.$bvToast.toast('Wireless Mac Address number is more than Asset Quantity', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.$nextTick(() => {
              this.$refs.myInputs[16].focus();
          });
          }
        }
         }
      }
    },
    openAssetBasedItemType() {
      if (this.itemType.value) {
        this.openValueSetModal('FA_ASSET_ITEMS');
      } else {
        // alert('Please Select Item Type');
        this.$bvToast.toast('Please Select Item Type', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.itemTypeInput.focus();
      });
      } 
    },
    openManufacturerBaseModelType(){
      if (this.grnassets[28].value) {
        this.openValueSetModal('FA_ASSET_MODEL_TYPE');
      } else {
        // alert('Please Select Type Of Manufacturer');
        this.$bvToast.toast('Please Select Type Of Manufacturer', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.manufacturerInput[0].focus();
      });
      } 
    },
    openProcessorType(){
      if (this.assetItem.text=="LAPTOP" || this.assetItem.text=="DESKTOP") {
        this.openValueSetModal('FA_ITEM_PROCESSOR');
      }
      else if (this.assetItem.text && (this.assetItem.text!=="LAPTOP"|| this.assetItem.text!=="DESKTOP")) {
        this.$bvToast.toast('Asset Item should be either LAPTOP or DESKTOP', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.assetItemInput.focus();
      });
      }
       else {
        // alert('Please Select Type Of Manufacturer');
        this.$bvToast.toast('Please Select An Asset Item', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.assetItemInput.focus();
      });
      } 
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
        this.parent_value_set_id = this.itemType.value;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MODEL_TYPE){
        this.parent_value_set_id = this.modelValueSetId;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_PROCESSOR){
        this.parent_value_set_id = this.assetItemId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_TYPE) {
        this.itemType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
        this.assetItemId=item.item_id
        this.grnassets.map(item =>
          item.classes=null,
          )
          this.checkFlag = null,
        this.assetItem = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.data = item;
        for (let i in this.data) {
          for (let j = 0; j < this.grnassets.length; j++) {
            if (i === this.grnassets[j].key && this.data[i] === 'TRUE') {
              this.grnassets[j].classes = 'mandatory';
              this.checkFlag++;
            }
          }
        }
        this.grnassets[17].classes= 'mandatory';
        this.checkFlag++;
        this.grnassets[18].classes= 'mandatory';
        this.checkFlag++;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_TAGS) {
        this.grnassets[27].value.push(item.value_code);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FA_DEPARTMENT) {
        this.grnassets[33].value = item.value_code;
        this.grnassets[33].meaning = item.value_meaning;
      }else if(this.vsetCode === appStrings.VALUESETTYPE.ASSET_TYPE_OF_WARRANTY){
        this.grnassets[25].value = item.value_code;
        this.grnassets[25].meaning = item.value_meaning;
      }else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MANUFACTURER){
        this.grnassets[28].value = item.value_code;
        this.grnassets[28].meaning = item.value_meaning;
        this.modelValueSetId=item.value_set_dtl_id
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MODEL_TYPE){
        this.grnassets[29].value = item.value_code;
        this.grnassets[29].meaning = item.value_meaning;
      }
      else if(this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_PROCESSOR){
        this.grnassets[30].value = item.value_set_dtl_id;
        this.grnassets[30].meaning = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'FA_ITEM_TYPE':
          this.itemType = {
            text: null,
            value: null
          };
          break;
        case 'FA_ASSET_ITEMS':
          this.assetItem = {
            text: null,
            value: null
          };
          break;
          case 'ASSET_TYPE_OF_WARRANTY':
            this.grnassets[25].value = null;
            this.grnassets[25].meaning = null;
            break;
          case 'FA_ASSET_MANUFACTURER':
            this.grnassets[28].value = null;
            this.grnassets[28].meaning = null;
            this.grnassets[29].value = null;
            this.grnassets[29].meaning = null;
            break;
            case 'FA_ASSET_MODEL_TYPE':
              this.grnassets[29].value = null;
              this.grnassets[29].meaning = null;
              break; 
              case 'FA_ITEM_PROCESSOR':
                this.grnassets[30].value = null;
                this.grnassets[30].meaning = null;
                break; 
      }
    },
    removeTags(tags, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
    },

    adjustTagsWidth() {
      const tagsWidth = this.$refs.tags.$el.scrollWidth;
      this.$refs.tags.$el.style.width = `${tagsWidth}px`;
    },

    dateFunc() {
      if (!this.grnassets[17].value) {
        // alert('Select Date Received first');
        this.$bvToast.toast('Select Date Received first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        this.$nextTick(() => {
          this.$refs.dateInput[0].focus();
      });
      }
    },
    validateInput() {
        this.assetCreateQty = this.assetCreateQty.replace(/[^0-9]/g, '');
        this.validationError = '';
        if (this.assetCreateQty === '') {
          this.validationError = '*Please enter a whole number.';
          return;
        }
      },
      formattedCurrency(value) {
        this.grnassets[10].value= commonHelper.formatCurrencyAmount(value);
      },
      checkHsn(item){
        if((item.length<8 && item.length>6) || (item.length<6)){
              this.$bvToast.toast("HSN code length should be of 6 or 8 digits", {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            //   this.$nextTick(() => {
            //     this.$refs.hsnInput[0].focus();
            // });
            this.hsnFlag=true
        }else{
          this.hsnFlag=false
        }
      }
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
