import commonHelper from '@/app/utility/common.helper.utility';
import specificGrnModal from './specificGrnModal';
import grnAssetDataModal from './grnAssetDataModal';
import createGrnAsset from './createGrnAsset';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import poNoModal from './poNoModal';
import { required } from 'vuelidate/lib/validators';
import receiveAsset from '../../receiveAsset';
export default {
  name: 'addEditCreateGrnAsset',
  components: {
    specificGrnModal,
    DatePicker,
    grnAssetDataModal,
    createGrnAsset,
    poNoModal,
    receiveAsset
  },
  props: ['currentLoc', 'erpLoctnOrgId','locId','getDashboardData','locAccessFlag'],
  watch: {
    currentPage: function() {
      this.getResponsibility();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getResponsibility();
    },
    currentPage1: function() {
      this.getPoDetails();
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getPoDetails();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showAddTransferAssetModal: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      currentPage: 1,
      currentPage1: 1,
      totalRows: null,
      totalRows1: null,
      perPage: commonHelper.perPageRecord,
      perPage1: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      ponumber: null,
      grnnumber: null,
      flag: false,
      grnAssetData: [],
      grnAssetFields: [
        {
          key: 'create_asset',
          class: 'ponum'
        },
        {
          key: 'view_asset',
          class: 'ponum'
        },
        // {
        //   key: 'grn_num',
        //   label: 'GRN Number',
        //   class: 'grnnumb'
        // },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name'
        },
        {
          key: 'po_number',
          label: 'PO No.',
          class: 'ponum'
        },
        // {
        //   key: 'po_status',
        //   label: 'PO Status'
        // },
        {
          key: 'po_date',
          label: 'PO Date'
        },
        {
          key: 'line_num',
          label: 'Line No.'
        },
        {
          key: 'item_code'
          // class: 'itemc'
        },
        {
          key: 'item_desc',
          label: 'Item Description'
        },
        {
          key: 'type',
          class: 'type'
        },
        {
          key: 'fa_cat',
          label: 'FA Category'
          // class: 'facat'
        },
        {
          key: 'order_qty',
          label: 'PO Qty',
          class: 'poquant'
        },
        {
          key: 'qty_received',
          label: 'Received Qty',
          class: 'quant'
        },
        {
          key: 'qty_cancelled',
          label: 'Cancelled Qty',
          class: 'quant'
        },
        {
          key: 'open_qty',
          label: 'Pending Qty',
          class: 'quant'
        },
        {
          key:'realapp_pending_quantity',
          label:'Realapp Pending Qty',
          class: 'quant'
        },
        {
          key:'realapp_received_quantity',
          label:'Realapp Received Qty',
          class: 'quant'
        },
        {
          key: 'price',
          class: 'price'
        },
        {
          key: 'hsn_code',
          label: 'HSN code',
          class: 'hsn'
        }
      ],
      allFields: false,
      rowData: null,
      createRowData: null,
      openPoNoModal: false,
      showHideGrnAssetsFlag: false,
      createGrnAssetModal: false,
      showValueSetModal: false,
      showExcelUploadModal: false,
      legalEntity: {
        text: null,
        value: null
      },
      erpOrgId: null,
      erpLocOrgId: null,
      poNoData: [],
      poNoFields: [
          {
            key: 'le_name',
            label: 'Legal Entity',
            class:'poquant'
          },
          {
            key: 'ou_name',
            label: 'Operating Unit',
            class:'poquant'
          },
          {
            key: 'vendor_name',
            label: 'Vendor Name',
            class:'poquant'
          },
          {
            key: 'buyer_name',
            label: 'Buyer Name',
            class:'poquant'
          },
          {
            key: 'buyer_email',
            label: 'Buyer Email',
            class:'poquant'
          },
          {
            key: 'po_number',
            label: 'PO No.',
            class:'poquant'
          },
          {
            key: 'po_date',
            label: 'PO Date',
            class:'poquant'
          },
          {
            key:'po_hdr_status',
            label:'PO Status',
            class:'poquant'
          },
          {
            key: 'hdr_ship_to_loc_name',
            label: 'PO Ship To Location',
            class:'poquant'
          },
          {
            key: 'po_line_num',
            label: 'PO Line No.',
            class:'poquant'
          },
          {
            key: 'line_ship_to_location_name',
            label: 'PO Line Ship To Location',
            class:'poquant'
          },
          {
            key: 'po_line_location_status',
            label: 'PO Line Status',
            class:'poquant'
          },
          {
            key: 'item_code',
            class:'poquant'
            // class: 'itemc'
          },
          {
            key: 'item_desc',
            label: 'Item Description',
            class:'poquant'
          },
          {
            key: 'type',
            class: 'type',
          },
          {
            key: 'fa_cat',
            label: 'FA Category',
            class:'poquant'
            // class: 'facat'
          },
          {
            key: 'order_qty',
            label: 'PO Qty',
            class:'poquant'
          },
          {
            key: 'qty_received',
            label: 'Received Qty', 
            class:'poquant'
          },
          {
            key: 'qty_cancelled',
            label: 'Cancelled Qty',
            class:'poquant'
          },
          {
            key: 'open_qty',
            label: 'Pending Qty',
            class:'poquant'
          },
          {
            key: 'price',
            class:'poquant'
          },
          {
            key: 'hsn_code',
            label: 'HSN code',
            class:'poquant'
          }
      ],
      currentLocation: {
        text: null,
        value: null
      },
      locFlag: false,
      poHdrId:null,
      batchStat:'IT',
      accessButtons:{},
      showPoModalFlag:false,
      poSearchNumber:null,
    };
  },
  validations: {
    currentLocation: {
      text: {
        required
      }
    }
    // legalEntity: {
    //   text: {
    //     required
    //   }
    // },
    // ponumber: {
    //   required
    // }
  },
  mounted() {
    if (this.currentLoc) {
      this.locFlag = true;
      (this.currentLocation.text = this.currentLoc.text),
        (this.currentLocation.value = this.currentLoc.value),
        (this.erpLocOrgId = this.erpLoctnOrgId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        // if (actionName === 'save' && this.createGrnAssetModal) {
        // this.createGrnAssetModal = false;
        // this.viewAsset(true, this.rowData);
        // this.addEditCreateGrnAsset();
        // }
        if (
          actionName === 'upload' &&
          !this.createGrnAssetModal &&
          !this.showHideGrnAssetsFlag
        ) {
          if (this.poHdrId) {
            this.showExcelUploadModal = true;
          } else {
            alert('Kindly press search button');
          }
        }
      }
    });
  },
  methods: {
    closePoModal(poNumber) {
      if (poNumber) {
        this.ponumber = poNumber;
        this.showHidePoNoModal(false);
      }
    },
    getResponsibility() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          po_number: this.ponumber,
          // le_id: this.erpOrgId,
          loc_id: this.erpLocOrgId,
          grn_number: this.grnnumber
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getGrnAsset', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.grnAssetData = resp.data.data.data.page;
              this.totalRows = resp.data.data.data.total_elements;
              this.poHdrId = this.erpLocOrgId;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clearData() {
      this.grnAssetData = null;
      this.ponumber = null;
      this.grnnumber = null;
      this.poHdrId = null;
      this.legalEntity = {
        value: null,
        text: null
      };
      this.erpOrgId = null;
      this.erpLocOrgId = null;
      this.perPage = null;
      this.perPage1=null;
      this.totalRows = null;
      this.totalRows1 = null;
      this.currentLocation = {
        value: null,
        text: null
      };
    },
    showHideGrnAssets(flag, item) {
   if(flag && item.realapp_received_quantity==0){
        this.$bvToast.toast('Kindly create assets first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
      else{
        this.showHideGrnAssetsFlag = flag;
        this.rowData = item;
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    showHidePoNoModal(flag) {
      // if(this.legalEntity.text && this.currentLocation.text){
      this.openPoNoModal = flag;
      // if (flag) {
      //   this.getPoList();
      // }
      // }
      // else {
      //   alert('Please select Legal Entity & Location');
      // }
    },
    // getPoList() {
    //     const payload = {
    //       _page: this.currentPage - 1,
    //       _limit: this.perPage,
    //       po_number: this.ponumber,
    //       le_id: this.erpOrgId,
    //       loc_id: this.erpLocOrgId
    //     };
    //     this.loader = true;
    //     this.$store
    //       .dispatch('assets/getGrnAsset', payload)
    //       .then(resp => {
    //         this.loader = false;
    //         if (resp.status === 200) {
    //           this.poNoData = resp.data.data.data.page;
    //         }
    //       })
    //       .catch(() => {
    //         this.loader = false;
    //       });
    // },
    rowSelected() {
      this.openPoNoModal = false;
    },
    showHideCreateGrnAssets(flag, item) {
      if(flag && item.realapp_pending_quantity==0){
        this.$bvToast.toast('Assets can not be created for this PO', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }else{
        this.createGrnAssetModal = flag;
        this.createRowData = item;
        this.$store.commit('assets/createModalFlag', this.createGrnAssetModal);
      }

      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.erpOrgId = item.erp_org_id;
          break;
        case appStrings.VALUESETTYPE.LOCATION:
          this.currentLocation = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.erpLocOrgId = item.erp_loc_org_id;
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
        this.erpOrgId = null;
      } else if (vsetCode === this.currentLocation.value) {
        this.currentLocation = {
          value: null,
          text: null
        };
        this.erpLocOrgId = null;
      } else if (vsetCode === this.ponumber) {
        this.ponumber = null;
      }
      else if (vsetCode === this.poSearchNumber) {
        this.poSearchNumber = null;
        this.poNoData=[];
        this.totalRows1=null;
      }
    },
    formattedCurrency(value) {
      return commonHelper.formatCurrencyAmount(value);
    },
    showPoModal(flag){
      this.showPoModalFlag=flag;
    },
    getPoDetails(){
      if (this.poSearchNumber) {
        const payload = {
          po_number: this.poSearchNumber,
          _page: this.currentPage1 - 1,
          _limit: this.perPage1,
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getUserDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const res = resp.data.data.page;
              this.poNoData= res
              this.totalRows1 = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }else{
        this.$bvToast.toast('Kindly fill the mandatory field', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
