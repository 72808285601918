import commonHelper from '@/app/utility/common.helper.utility';
import addEditCreateGrnAsset from './addEditCreateGrnAsset';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'grnAsset',
  components: { addEditCreateGrnAsset },
  watch: {
    currentPage: function() {
      this.getGrnAssetGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGrnAssetGridData();
    },
    tags() {
      this.adjustTagsWidth();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showAddGrnAssetModal: false,
      grnNo: null,
      poNo: null,
      legalEntity: {
        text: null,
        value: null
      },
      ouName: {
        value: null,
        text: null
      },
      bookType: {
        text: null,
        value: null
      },
      category: {
        text: null,
        value: null
      },
      mapped: true,
      showValueSetModal: false,
      parent_value_set_id: null,
      defaultValue: {
        value: null,
        text: null
      },
      assetTags: [],
      grnAssetData: [{}],
      grnAssetFields: [
        { key: 'serial_no' },
        {
          key: 'receipt_num',
          label: 'GRN No.'
        },
        { key: 'po_number', label: 'PO No.' },
        { key: 'le_name', label: 'Legal Entity' },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        // { key: 'asset_type_vset',
        //   label: 'Asset Type' },
        { key: 'asset_category_name' },
        { key: 'asset_status' },
        { key: 'book_type_code' },
        { key: 'creation_date' },
        {
          key: 'created_by'
        },
        {
          key: 'item_code'
        },
        { key: 'description' },
        // { key: 'doc_num' },
        {
          key: 'fa_loc_name',
          label: 'FA Location Name'
        },
        { key: 'fa_loc_name', label: 'FA Loc Name' },
        { key: 'hr_base_loc_name', label: 'Base Location Name' },
        { key: 'hr_loc_name', label: 'HR Loc Name' },
        { key: 'hsn_sac_code', label: 'HSN SAC Code' },
        { key: 'invoice_number' },
        { key: 'last_trf_date' },
        { key: 'manufacturer_name' },
        { key: 'model_no' },
        { key: 'tag' },
        { key: 'tag_number' },
        { key: 'new_tag_number' },
        // { key: 'owned_leased' },
        { key: 'purchase_date' }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideAddGrnAsset(true);
        }
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'assets/getGrnAssetGridData',
            'grn-asset',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getGrnAssetGridData() {
      const payload = {
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        },
        params: {
          grn_number: this.grnNo,
          po_number: this.poNo,
          asset_category_id: this.category.value,
          book_type_code: this.bookType.value,
          le_id: this.legalEntity.value,
          ou_id: this.ouName.value,
          is_mapped: this.mapped,
          tags: this.assetTags
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getGrnAssetGridData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.grnAssetData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideAddGrnAsset(flag) {
      this.showAddGrnAssetModal = flag;
    },
    openValueSetModal(vsetCode) {
      // this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_BOOK_TYPE) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_CATEGORY) {
        this.parent_value_set_id = this.bookType.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    chooseValueset(name) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'ou_list':
          this.openValueSetModal(appStrings.VALUESETTYPE.OU_LIST);
        break;
        case 'book_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'category':
          if (this.bookType.value) {
            this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_CATEGORY);
            break;
          } else {
            alert('Please Select Book Type');
          }
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        case appStrings.VALUESETTYPE.ASSET_CATEGORY:
          this.parent_value_set_id = this.bookType.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookType = {
            value: null,
            text: null
          };
          this.ouName = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          this.ouName = {
            value: item.org_id,
            text: item.org_name
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_CATEGORY:
          this.category = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.FA_ASSET_TAGS:
          this.assetTags.push(item.value_code);
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
        this.ouName = {
          text: null,
          value: null
        }
        this.bookType = {
          text: null,
          value: null
        };
        this.category = {
          text: null,
          value: null
        };
      } else if(vsetCode === this.ouName.value){
        this.ouName={
          text: null,
          value: null
        }
      } else if (vsetCode === this.bookType.value) {
        this.bookType = {
          text: null,
          value: null
        };
        this.category = {
          text: null,
          value: null
        };
        this.parent_value_set_id = null;
      } else if (vsetCode === this.category.value) {
        this.category = {
          text: null,
          value: null
        };
      }
    },
    clearSearchFilters() {
      this.grnNo = null;
      this.poNo = null;
      this.category = this.defaultValue;
      this.legalEntity = this.defaultValue;
      this.ouName = this.defaultValue;
      this.bookType = this.defaultValue;
      this.mapped = false;
      this.grnAssetData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
      this.currentPage = 1;
      this.assetTags = [];
    },

    removeTags(tags, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
    },
    adjustTagsWidth() {
      const tagsWidth = this.$refs.tags.$el.scrollWidth;
      this.$refs.tags.$el.style.width = `${tagsWidth}px`;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
