// import commonHelper from '@/app/utility/common.helper.utility';
// import appStrings from '@/app/utility/string.utility';
export default {
  name: 'copyModal',
  components: {},
  props: ['copyData'],
  data() {
    return {
      masterAsset: {
        text: null,
        value: null
      },
      loader: false,
      unsubscribe: null,
      parent_value_set_id: null,
      depVsetParam: null,
      gstAssetParam: null,
      showValueSetModal: false,
      dataObj:{
        data:null,
        asstId:null
      }
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditGrnAsset();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      this.gstAssetParam = null;
      if (this.vsetCode == 'GET_ASSET_MASTER') {
        this.parent_value_set_id = this.copyData.item_code;
        this.depVsetParam = this.copyData.grn_num;
        this.gstAssetParam = this.copyData.po_number;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode == 'GET_ASSET_MASTER') {
        this.masterAsset = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'GET_ASSET_MASTER':
          this.masterAsset.text = null;
          this.masterAsset.value = null;
          break;
      }
    },
    getCopyAssetMasterData() {
      const payload = {
        asset_id: this.masterAsset.value
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getCopyAssetMasterData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.copyResponse = resp.data.data;
            this.dataObj.data=this.copyResponse;
            this.dataObj.asstId=this.masterAsset.value
            this.$emit('closeModal', this.dataObj);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
